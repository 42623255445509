<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Tenant Statistics</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">
      <span class="font-weight-semibold text--primary me-1">Last updated: {{ df(tenantData.metrics['updated_at']) }}</span>
    </v-card-subtitle>

    <!-- warning primary secondary success -->

    <v-card-text>
      <v-row>
        <v-col
          cols="8"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="primary"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Monthly Active Accounts
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ tenantData.metrics.maa }}
            </h3>
          </div>
        </v-col>
        <v-col
          cols="8"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="primary"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiApi }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Monthly API Calls
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ tenantData.metrics.mac }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiTrendingUp, mdiApi, mdiCurrencyEur } from '@mdi/js'

export default {
  /**
   * props
   */
  props: {
    /**
     * tenantData
     */
    tenantData: {
      type: Object,
      default: () => {},
    },
  },

  /**
   * methods
   */
  methods: {
    /**
     * df
     */
    df(isoString) {
      if (!isoString) return '(not yet available)'

      return new Date(isoString).toLocaleString()
    },
  },

  /**
   * setup
   */
  setup() {
    return {
      icons: {
        mdiTrendingUp,
        mdiAccountOutline,
        mdiApi,
        mdiCurrencyEur,
      },
    }
  },
}
</script>
